var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tableCompont" },
    [
      _c(
        "el-table",
        {
          staticClass: "is-grey",
          attrs: {
            "highlight-current-row": "",
            size: "medium",
            height: "100%",
            data: _vm.tabData,
            "row-class-name": _vm.tableRowClassName
          },
          on: { "filter-change": _vm.handleFilterChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              "min-width": "100px",
              label: "序号",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "200px",
              "header-align": "left",
              align: "left",
              label: "条文内容",
              prop: "content"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("div", {
                      staticStyle: { width: "100%", overflow: "hidden" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.xss(row.content.replaceAll("\n", "<br>"))
                        )
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "70px",
              label: "案例来源",
              align: "center",
              filters: _vm.filtrate.sourceDicList,
              prop: "sourceNames",
              "column-key": "sourceDicList"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "50px",
              label: "所属业态",
              align: "center",
              filters: _vm.filtrate.formatDicList,
              prop: "formatName",
              "column-key": "formatDicList"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "50px",
              label: "所属角色",
              align: "center",
              prop: "functionName"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "50px",
              label: "模块",
              align: "center",
              filters: _vm.filtrate.moduleDicList,
              prop: "moduleName",
              "column-key": "moduleDicList"
            }
          }),
          _c("el-table-column", {
            attrs: {
              "min-width": "50px",
              label: "部位",
              align: "center",
              filters: _vm.filtrate.positionDicList,
              prop: "positionName",
              "column-key": "positionDicList"
            }
          }),
          _vm.type === 1
            ? _c("el-table-column", {
                attrs: { label: "操作", "min-width": "60px", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.articleEdit(row)
                                }
                              }
                            },
                            [_vm._v("编辑")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteClick(row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2425598718
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }