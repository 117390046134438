import request from '@/utils/request';
import beautyRequst from '@/utils/beautyRequest';
import { getValidToken } from '@/utils/storage';
export default {
  ckpDic: function ckpDic() {
    return request.get("/app-doc-center/v1/ckp/dic").then(function (res) {
      return res;
    });
  },
  getDicContent: function getDicContent() {
    return request.get("/app/v1/dic/list-dicContent?dicCode=RoleTag");
  },
  ckplist: function ckplist(data) {
    return request.post("/app-doc-center/v1/ckp/list", data).then(function (res) {
      return res;
    });
  },
  ckpAdd: function ckpAdd(data) {
    return request.post("/app-doc-center/v1/ckp/add", data).then(function (res) {
      return res;
    });
  },
  ckpDelete: function ckpDelete(ckpId) {
    return request.post("/app-doc-center/v1/ckp/delete?ckpId=".concat(ckpId)).then(function (res) {
      return res;
    });
  },
  ckpRelease: function ckpRelease() {
    return request.post("/app-doc-center/v1/ckp/release").then(function (res) {
      return res;
    });
  },
  ckpGet: function ckpGet(ckpId) {
    return request.get("/app-doc-center/v1/ckp/get?ckpId=".concat(ckpId)).then(function (res) {
      return res;
    });
  },
  ckpEdit: function ckpEdit(data) {
    return request.post("/app-doc-center/v1/ckp/edit", data).then(function (res) {
      return res;
    });
  },
  ckpReleaseRecord: function ckpReleaseRecord() {
    return request.get("/app-doc-center/v1/ckp/release/record/list").then(function (res) {
      return res;
    });
  },
  releaseRecordDetailList: function releaseRecordDetailList(data) {
    return request.post("/app-doc-center/v1/ckp/release/record/detail/list", data).then(function (res) {
      return res;
    });
  },
  exportExcel: function exportExcel(params) {
    return beautyRequst.post('/app-doc-center/v1/ckp/export/excel', params, {
      responseType: 'blob'
    });
  },
  importExcel: function importExcel(params) {
    return beautyRequst.post('/app-doc-center/v1/ckp/import/excel', params, {
      responseType: 'blob',
      token: getValidToken()
    });
  },
  downImportExcel: function downImportExcel() {
    return beautyRequst.get('/app-doc-center/v1/ckp/import/downloadTemplate', {
      responseType: 'blob',
      token: getValidToken()
    });
  }
};